import React from 'react';
import Assign from './Assign/Assign';
// import Profile from './Profile/Profile';
import Navbar from './navbar/Navbar';

const AssignUsers = () => {
  return (
    <div>
      <Navbar />
      <Assign />
    </div>
  );
};

export default AssignUsers;
