import React, { useState } from "react";
import axios from "axios";
import { Navigation } from "./Pages/navigation";

const Advertise = () => {

  const [state, setState] = useState({ name: "", email: "", phone:"", message: "" });
  const [loader, setloader] = useState('');
  const [errors, setErrors] = useState({ name: "", email: "", phone:"", message: "" });
  const [submissionMessage, setSubmissionMessage] = useState("");


  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setState({
      ...state,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (state.email && state.name && state.message) {
      debugger;
      setloader('loading');
      const url = "https://cms.vervebot.io//wp-json/contact-form-7/v1/contact-forms/10346/feedback";
      const formData = new FormData();
      formData.append("your-name", state.name);
      formData.append("your-email", state.email);
      formData.append("your-number", state.phone);
      formData.append("your-message", state.message);

      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      };

      const res = await axios.post(url, formData, config);

      setloader(res.data.message)
      if (typeof res.data.message !== undefined) {
        //alert(res.data.message);
        setSubmissionMessage(res.data.message);
        setState({ name: "", email: "", phone:"", message: "" });
      } else {
        alert("please try again");
      }
    } else {
      let error = errors;
      if (state.name === "") {
        // debugger;
        error = {
          ...error,
          name: "This is required",
        };
      }
      if (state.email === "") {
        // debugger;
        error = {
          ...error,
          email: "This is required",
        };
      }
      if (state.phone === "") {
        // debugger;
        error = {
          ...error,
          phone: "This is required",
        };
      }
      if (state.message === "") {
        // debugger;
        error = {
          ...error,
          message: "This is required",
        };
      }
      setErrors(error);
    }
    //console.log(res.data.message, "res");
  };

  return (
    <div>
      <Navigation/>
      <section className="contact-one" style={{margin:"15% 5%"}}>
        <div className="container">
          <h2 className="contact-one__title text-center" style={{margin:"2%",fontWeight:"700"}}>
            Get in touch with us
          </h2>

          <form
            action="#"
            method="post"
            className="contact-one__form contact-form-validated"
            noValidate="novalidate"
           style={{background:"#fff", padding:"5%", marginBottom:"5%"}}>
            <div className="row low-gutters">
              <div className="col-lg-12" style={{textAlign:"center"}}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={state.name}
                  onChange={handleInputChange}
               style={{width:"90%", padding:"1% 0", margin:"1%", padding:"1%"}} />
                <small style={{ color: "red" }}>{errors.name}</small>
              </div>
              <div className="col-lg-12" style={{textAlign:"center"}}>
                <input
                  type="text"
                  placeholder="Email Address"
                  name="email"
                  value={state.email}
                  onChange={handleInputChange}
                  style={{width:"90%", padding:"1% 0", margin:"1%", padding:"1%"}}     />
                <small style={{ color: "red" }}>{errors.email}</small>
              </div>
              <div className="col-lg-12" style={{textAlign:"center"}}>
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                  value={state.phone}
                  onChange={handleInputChange}
                  style={{width:"90%", padding:"1% 0", margin:"1%", padding:"1%"}}      />
                <small style={{ color: "red" }}>{errors.phone}</small>
              </div>
              <div className="col-lg-12" style={{textAlign:"center"}}>
                <textarea
                  placeholder="Write Message"
                  name="message"
                  value={state.message}
                  onChange={handleInputChange}
                  style={{width:"90%", padding:"1% 0", margin:"1%", padding:"1%"}}       ></textarea>
                <small style={{ color: "red" }}>{errors.message}</small>
                <div className="text-center">
                  {/* <button
                    type="submit"
                    className="contact-one__btn thm-btn"
                    onClick={submitHandler}
                  >
                    Submit Comment<div class="loader" style={{ bottom: "30px", height: "20px", width: "20px" }}></div>
                  </button> */}

                  {loader == 'loading' ? <button
                    type="submit"
                    onClick={submitHandler}
                    className="contact-one__btn thm-btn"
                    disabled
                    style={{position:"relative",background:"#76bbfc"}}
                  >Submit<div class="loader" style={{ bottom: "30px", height: "20px", width: "20px" }}></div></button> : <button
                    type="submit"
                    onClick={submitHandler}
                    className="contact-one__btn thm-btn"
                 style={{background:"#76bbfc", color:"#000", padding:"1% 5%"}} >Submit</button>}
                  <small style={{ color: "green" }}>{submissionMessage}</small>
                </div>
              </div>
            </div>
          </form>

          <div className="result text-center"></div>
        </div>
      </section>

    </div>
  );
};

export default Advertise;