import React from 'react';
import Send from './Lists/Send';
import Profile from './Profile/Profile';
import Navbar from './navbar/Navbar';

const Sends = () => {
  return (
    <div>
    <Navbar />
    <Send />
  </div>
  )
}

export default Sends