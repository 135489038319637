import React from 'react'
import  Justin  from '../../img/justin.gif';

const Cancel = () => {
  return (
    <center>
        <img style={{width:"50rem"}} src={Justin}></img>
    </center>
  )
}

export default Cancel